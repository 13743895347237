import { getSocialIcon } from "@utils/mapping";
import "./index.scss";
import {useSelector} from "react-redux";

export default function SocialCards({ data = {} }: any) {

    const { rtl } = useSelector((state: any) => state.utils);
    const direction = rtl === "ar" ? "change-align" : '';
  return (
    <div className="social-card">
      <span>{getSocialIcon("twitter")}</span>
      {/*<div className="social-card-header ">*/}
      {/*  <h3 className="text-truncate">*/}
      {/*    {data?.highlight?.title?.text || data?.source?.title}*/}
      {/*  </h3>*/}
      {/*</div>*/}
      <p
          className={`m-0 ${direction}`}
        dangerouslySetInnerHTML={{
          __html: data?.highlight?.body?.text || data?.source?.body,
        }}
      ></p>
    </div>
  );
}
