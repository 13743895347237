import { Route, Routes } from "react-router-dom";

import Login from "../../views/Login";
import Dashboard from "../../views/Dashboard";
import ResultPage from "../../views/ResultPage";
import PdfView from "../../views/Pdf/Pdf";
import { PrivateOutlet } from '@components/Routes/PrivateRoutes'

const RoutesUI = () => {
  return (
    <Routes >
      <Route path="/" element={<Login />} />

      <Route path="/dashboard/results/pdf" element={
        <PrivateOutlet>
          <PdfView />
        </PrivateOutlet>
      } />

      <Route path="/dashboard/results" element={
        <PrivateOutlet>
          <ResultPage />
        </PrivateOutlet>
      } />

      <Route path="/dashboard" element={
        <PrivateOutlet>
          <Dashboard />
        </PrivateOutlet>
      } />

      {/* <Route path='*' element={<NotFound />} /> */}
    </Routes>

  );
};

export default RoutesUI;
