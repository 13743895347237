import SmallSentiment from "@assets/img/sentiment-small.png";
import BigSentiment from "@assets/img/Sentiment-PDF.png";
import LineGraph from "@assets/img/LineGraph.png";

export const pdfData = [
  {
    lang: "ar",
    title: "Local News",
    overview: `من عام 0202 إلى الربع الأول من عام 2202 ، تم تحليل 5 مصادر بيانات (000،000،2 وثائق) مع 0003 وثيقة تتعلق بجودة الهواء.
    كانت المشاعر العامة تجاه جودة الهواء في الأخبار العربية 06٪ إيجابية و 01٪ سلبية و 03٪ حيادية.
    `,
    sentimentWithLabels: `${BigSentiment}`,
    perception: [
      {
        title: "تصور إيجابي",
        text: `كان لدى 0081 وثيقة تصور عام إيجابي عن جودة الهواء ، وقد ذكرت هذه المقالات الأشخاص التالية أسماؤهم: خالد أحمد ، وسعد مسفر ، وطارق محمد ، وذكرت المنظمات التالية: وزارة المياه ، ووزارة الشؤون الريفية ، ومركز مراقبة البيئة. \ n \ n علاوة على ذلك ، ذكر التصور العام الإيجابي لجودة الهواء الأحداث التالية المبادرة الخضراء ومنتدى البيئة العالمية وقمة مجموعة العشرين. علاوة على ذلك ، ارتبطت جودة الهواء بالإشارات الإيجابية التالية: حياد الكربون ، +02 مليون شجرة و +002 مركز جودة الهواء.`,
        table: {
          title: "ملخص إيجابي )0081 وثيقة(",
          headers: ["الناس", "منظمة", "الأحداث", "النواحي"],
          body: [
            ["خالد احمد", "وزارة المياه", "المبادرة الخضراء", "حياد الكربون"],
            ["سعد مسفر", "وزارة المياه", "العالمية Env. المنتدى", "+20 م شجرة"],
            [
              "طارق محمد",
              "وزارة المياه",
              "قمة مجموعة العشرين",
              "+200 مركز جودة هواء",
            ],
          ],
        },
      },
      {
        title: "تصور سلبي",
        text: `970 وثيقة لها تصور عام سلبي عن جودة الهواء ، وقد ذكرت هذه المقالات الأشخاص التالية أسماؤهم: خالد أحمد ، سعد مسفر وطارق محمد ، وذكرت المنظمات التالية: وزارة المياه ، وزارة الشؤون الريفية ، مركز مراقبة البيئة.
                
        علاوة على ذلك ، أشار التصور العام الإيجابي لجودة الهواء إلى الأحداث التالية: المبادرة الخضراء ، ومنتدى البيئة العالمية ، وقمة مجموعة العشرين. علاوة على ذلك ، ارتبطت جودة الهواء بالإشارات الإيجابية التالية: حياد الكربون ، +02 مليون شجرة و +002 مركز جودة الهواء.`,
        table: {
          title: "ملخص سلبي )079 وثيقة(",
          headers: ["الناس", "منظمة", "الأحداث", "النواحي"],
          body: [
            ["خالد احمد", "وزارة المياه", "المبادرة الخضراء", "حياد الكربون"],
            ["سعد مسفر", "وزارة المياه", "العالمية Env. المنتدى", "+02 م شجرة"],
            [
              "طارق محمد",
              "وزارة المياه",
              "قمة مجموعة العشرين",
              "+002 مركز جودة هواء",
            ],
          ],
        },
      },
      {
        title: "تصور محايد",
        text: `485 وثيقة لها تصور عام محايد عن نوعية الهواء ، وقد ذكرت هذه المقالات الأشخاص التالية أسماؤهم: خالد أحمد ، سعد مسفر وطارق محمد ، وذكرت المنظمات التالية: وزارة المياه ، وزارة الشؤون الريفية ، مركز مراقبة البيئة.
                
        علاوة على ذلك ، أشار التصور العام الإيجابي لجودة الهواء إلى الأحداث التالية: المبادرة الخضراء ، ومنتدى البيئة العالمية ، وقمة مجموعة العشرين. علاوة على ذلك ، ارتبطت جودة الهواء بالإشارات الإيجابية التالية: حياد الكربون ، +02 مليون شجرة و +002 مركز جودة الهواء.`,
        table: {
          title: "ملخص محايد )485 وثيقة(",
          headers: ["الناس", "منظمة", "الأحداث", "النواحي"],
          body: [
            ["خالد احمد", "وزارة المياه", "المبادرة الخضراء", "حياد الكربون"],
            ["سعد مسفر", "وزارة المياه", "العالمية Env. المنتدى", "+20 م شجرة"],
            [
              "طارق محمد",
              "وزارة المياه",
              "قمة مجموعة العشرين",
              "+200 مركز جودة هواء",
            ],
          ],
        },
      },
    ],
    summery: [
      {
        title: "جانب",
        table: {
          // headers: ["Aspects", "Mentions", "Articles", "Sentiment"],
          headers: ["المشاعر", "مقالات", "يذكر", "جانب"],
          body: [
            // ["Aspect 1", 84, 26, `${SmallSentiment}`],
            // ["Aspect 2", 84, 26, `${SmallSentiment}`],
            // ["Aspect 3", 84, 26, `${SmallSentiment}`],
            // ["Aspect 4", 84, 26, `${SmallSentiment}`],
            [`${SmallSentiment}`, 62, 48, "الجانب 1"],
            [`${SmallSentiment}`, 62, 48, "الجانب 2"],
            [`${SmallSentiment}`, 62, 48, "الجانب 3"],
            [`${SmallSentiment}`, 62, 48, "الجانب 4"],
          ],
        },
      },
      {
        title: "الناس",
        table: {
          headers: ["المشاعر", "مقالات", "يذكر", "الناس"],
          body: [
            [`${SmallSentiment}`, 62, 48, "الناس 1"],
            [`${SmallSentiment}`, 62, 48, "الناس 2"],
            [`${SmallSentiment}`, 62, 48, "الناس 3"],
            [`${SmallSentiment}`, 62, 48, "الناس 4"],
          ],
        },
      },
      {
        title: "المنظمات",
        table: {
          headers: ["المشاعر", "مقالات", "يذكر", "المنظمات"],
          body: [
            [`${SmallSentiment}`, 62, 48, "المنظمات 1"],
            [`${SmallSentiment}`, 62, 48, "المنظمات 2"],
            [`${SmallSentiment}`, 62, 48, "المنظمات 3"],
            [`${SmallSentiment}`, 62, 48, "المنظمات 4"],
          ],
        },
      },
      {
        title: "المواقع",
        table: {
          headers: ["المشاعر", "مقالات", "يذكر", "موقع"],
          body: [
            [`${SmallSentiment}`, 62, 48, "موقع 1"],
            [`${SmallSentiment}`, 62, 48, "موقع 2"],
            [`${SmallSentiment}`, 62, 48, "موقع 3"],
            [`${SmallSentiment}`, 62, 48, "موقع 4"],
          ],
        },
      },
      {
        title: "الأحداث",
        table: {
          headers: ["المشاعر", "مقالات", "يذكر", "الأحداث"],
          body: [
            [`${SmallSentiment}`, 62, 48, "الأحداث 1"],
            [`${SmallSentiment}`, 62, 48, "الأحداث 1"],
            [`${SmallSentiment}`, 62, 48, "الأحداث 1"],
            [`${SmallSentiment}`, 62, 48, "الأحداث 1"],
          ],
        },
      },
    ],
    sentimentAnalysis: {
      title: "تحليل المشاعر عبر الزمن",
      image: `${LineGraph}`,
    },
  },
  {
    title: "Global News",
    overview: `From 2020 to Q1 2022, 5 data sources were analyzed (2,000,000 documents) with 3,000 documents related to Air Quality.
          
          The overall sentiment of Air Quality from Arabic news is %60 positive, %10 negative and %30 neutral.`,
    sentimentWithLabels: `${BigSentiment}`,
    perception: [
      {
        title: "Positive Perception",
        text: `1,800 documents had positive public perception on Air Quality, these articles had mentioned following persons: Khaled Ahmed, Saad Misfer and Tariq Mohammad and mentioned the following organizations: Ministry of Water, Ministry of Rural Affairs and Center of Environmental Control. \n\nMoreover, positive public perception on Air Quality, mentioned the following events Green initiative, Global Environment forum and G20 Summit. Furthermore, Air Quality had been associated with the following positive mentions: Carbon Neutrality, +20 million trees and +200 air quality centers.`,
        table: {
          title: "Positive Summary (1,800 documents)",
          headers: ["People", "Organization", "Events", "Aspects"],
          body: [
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
          ],
        },
      },
      {
        title: "Negative Perception",
        text: `970 documents had negative public perception on Air Quality, these articles had mentioned following persons: Khaled Ahmed, Saad Misfer and Tariq Mohammad and mentioned the following organizations: Ministry of Water, Ministry of Rural Affairs and Center of Environmental Control.
                
                Moreover, positive public perception on Air Quality, mentioned the following events Green initiative, Global Environment forum and G20 Summit. Furthermore, Air Quality had been associated with the following positive mentions: Carbon Neutrality, +20 million trees and +200 air quality centers.`,
        table: {
          title: "Negative Summary (970 documents)",
          headers: ["People", "Organization", "Events", "Aspects"],
          body: [
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
          ],
        },
      },
      {
        title: "Neutral Perception",
        text: `584 documents had neutral public perception on Air Quality, these articles had mentioned following persons: Khaled Ahmed, Saad Misfer and Tariq Mohammad and mentioned the following organizations: Ministry of Water, Ministry of Rural Affairs and Center of Environmental Control.
                
                Moreover, positive public perception on Air Quality, mentioned the following events Green initiative, Global Environment forum and G20 Summit. Furthermore, Air Quality had been associated with the following positive mentions: Carbon Neutrality, +20 million trees and +200 air quality centers.`,
        table: {
          title: "Neutral Summary (584 documents)",
          headers: ["People", "Organization", "Events", "Aspects"],
          body: [
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
          ],
        },
      },
    ],
    summery: [
      {
        title: "Aspects",
        table: {
          headers: ["Aspects", "Mentions", "Articles", "Sentiment"],
          body: [
            ["Aspect 1", 84, 26, `${SmallSentiment}`],
            ["Aspect 2", 84, 26, `${SmallSentiment}`],
            ["Aspect 3", 84, 26, `${SmallSentiment}`],
            ["Aspect 4", 84, 26, `${SmallSentiment}`],
          ],
        },
      },
      {
        title: "People",
        table: {
          headers: ["People", "Mentions", "Articles", "Sentiment"],
          body: [
            ["People 1", 84, 26, `${SmallSentiment}`],
            ["People 2", 84, 26, `${SmallSentiment}`],
            ["People 3", 84, 26, `${SmallSentiment}`],
            ["People 4", 84, 26, `${SmallSentiment}`],
          ],
        },
      },
      {
        title: "Organizations",
        table: {
          headers: ["Organizations", "Mentions", "Articles", "Sentiment"],
          body: [
            ["Organizations 1", 84, 26, `${SmallSentiment}`],
            ["Organizations 2", 84, 26, `${SmallSentiment}`],
            ["Organizations 3", 84, 26, `${SmallSentiment}`],
            ["Organizations 4", 84, 26, `${SmallSentiment}`],
          ],
        },
      },
      {
        title: "Locations",
        table: {
          headers: ["Location", "Mentions", "Articles", "Sentiment"],
          body: [
            ["Location 1", 84, 26, `${SmallSentiment}`],
            ["Location 2", 84, 26, `${SmallSentiment}`],
            ["Location 3", 84, 26, `${SmallSentiment}`],
            ["Location 4", 84, 26, `${SmallSentiment}`],
          ],
        },
      },
      {
        title: "Events",
        table: {
          headers: ["Events", "Mentions", "Articles", "Sentiment"],
          body: [
            ["Events 1", 84, 26, `${SmallSentiment}`],
            ["Events 2", 84, 26, `${SmallSentiment}`],
            ["Events 3", 84, 26, `${SmallSentiment}`],
            ["Events 4", 84, 26, `${SmallSentiment}`],
          ],
        },
      },
    ],
    sentimentAnalysis: {
      title: "Sentiment analysis across time",
      image: `${LineGraph}`,
    },
  },
  {
    lang: "ar",
    title: "Local Social Media",
    overview: `من عام 0202 إلى الربع الأول من عام 2202 ، تم تحليل 5 مصادر بيانات (000،000،2 وثائق) مع 0003 وثيقة تتعلق بجودة الهواء.
    كانت المشاعر العامة تجاه جودة الهواء في الأخبار العربية 06٪ إيجابية و 01٪ سلبية و 03٪ حيادية.
    `,
    sentimentWithLabels: `${BigSentiment}`,
    perception: [
      {
        title: "تصور إيجابي",
        text: `كان لدى 0081 وثيقة تصور عام إيجابي عن جودة الهواء ، وقد ذكرت هذه المقالات الأشخاص التالية أسماؤهم: خالد أحمد ، وسعد مسفر ، وطارق محمد ، وذكرت المنظمات التالية: وزارة المياه ، ووزارة الشؤون الريفية ، ومركز مراقبة البيئة. \ n \ n علاوة على ذلك ، ذكر التصور العام الإيجابي لجودة الهواء الأحداث التالية المبادرة الخضراء ومنتدى البيئة العالمية وقمة مجموعة العشرين. علاوة على ذلك ، ارتبطت جودة الهواء بالإشارات الإيجابية التالية: حياد الكربون ، +02 مليون شجرة و +002 مركز جودة الهواء.`,
        table: {
          title: "ملخص إيجابي )0081 وثيقة(",
          headers: ["الناس", "منظمة", "الأحداث", "النواحي"],
          body: [
            ["خالد احمد", "وزارة المياه", "المبادرة الخضراء", "حياد الكربون"],
            ["سعد مسفر", "وزارة المياه", "العالمية Env. المنتدى", "+20 م شجرة"],
            [
              "طارق محمد",
              "وزارة المياه",
              "قمة مجموعة العشرين",
              "+200 مركز جودة هواء",
            ],
          ],
        },
      },
      {
        title: "تصور سلبي",
        text: `970 وثيقة لها تصور عام سلبي عن جودة الهواء ، وقد ذكرت هذه المقالات الأشخاص التالية أسماؤهم: خالد أحمد ، سعد مسفر وطارق محمد ، وذكرت المنظمات التالية: وزارة المياه ، وزارة الشؤون الريفية ، مركز مراقبة البيئة.
                
        علاوة على ذلك ، أشار التصور العام الإيجابي لجودة الهواء إلى الأحداث التالية: المبادرة الخضراء ، ومنتدى البيئة العالمية ، وقمة مجموعة العشرين. علاوة على ذلك ، ارتبطت جودة الهواء بالإشارات الإيجابية التالية: حياد الكربون ، +02 مليون شجرة و +002 مركز جودة الهواء.`,
        table: {
          title: "ملخص سلبي )079 وثيقة(",
          headers: ["الناس", "منظمة", "الأحداث", "النواحي"],
          body: [
            ["خالد احمد", "وزارة المياه", "المبادرة الخضراء", "حياد الكربون"],
            ["سعد مسفر", "وزارة المياه", "العالمية Env. المنتدى", "+02 م شجرة"],
            [
              "طارق محمد",
              "وزارة المياه",
              "قمة مجموعة العشرين",
              "+002 مركز جودة هواء",
            ],
          ],
        },
      },
      {
        title: "تصور محايد",
        text: `485 وثيقة لها تصور عام محايد عن نوعية الهواء ، وقد ذكرت هذه المقالات الأشخاص التالية أسماؤهم: خالد أحمد ، سعد مسفر وطارق محمد ، وذكرت المنظمات التالية: وزارة المياه ، وزارة الشؤون الريفية ، مركز مراقبة البيئة.
                
        علاوة على ذلك ، أشار التصور العام الإيجابي لجودة الهواء إلى الأحداث التالية: المبادرة الخضراء ، ومنتدى البيئة العالمية ، وقمة مجموعة العشرين. علاوة على ذلك ، ارتبطت جودة الهواء بالإشارات الإيجابية التالية: حياد الكربون ، +02 مليون شجرة و +002 مركز جودة الهواء.`,
        table: {
          title: "ملخص محايد )485 وثيقة(",
          headers: ["الناس", "منظمة", "الأحداث", "النواحي"],
          body: [
            ["خالد احمد", "وزارة المياه", "المبادرة الخضراء", "حياد الكربون"],
            ["سعد مسفر", "وزارة المياه", "العالمية Env. المنتدى", "+20 م شجرة"],
            [
              "طارق محمد",
              "وزارة المياه",
              "قمة مجموعة العشرين",
              "+200 مركز جودة هواء",
            ],
          ],
        },
      },
    ],
    summery: [
      {
        title: "جانب",
        table: {
          // headers: ["Aspects", "Mentions", "Articles", "Sentiment"],
          headers: ["المشاعر", "مقالات", "يذكر", "جانب"],
          body: [
            // ["Aspect 1", 84, 26, `${SmallSentiment}`],
            // ["Aspect 2", 84, 26, `${SmallSentiment}`],
            // ["Aspect 3", 84, 26, `${SmallSentiment}`],
            // ["Aspect 4", 84, 26, `${SmallSentiment}`],
            [`${SmallSentiment}`, 62, 48, "الجانب 1"],
            [`${SmallSentiment}`, 62, 48, "الجانب 2"],
            [`${SmallSentiment}`, 62, 48, "الجانب 3"],
            [`${SmallSentiment}`, 62, 48, "الجانب 4"],
          ],
        },
      },
      {
        title: "الناس",
        table: {
          headers: ["المشاعر", "مقالات", "يذكر", "الناس"],
          body: [
            [`${SmallSentiment}`, 62, 48, "الناس 1"],
            [`${SmallSentiment}`, 62, 48, "الناس 2"],
            [`${SmallSentiment}`, 62, 48, "الناس 3"],
            [`${SmallSentiment}`, 62, 48, "الناس 4"],
          ],
        },
      },
      {
        title: "المنظمات",
        table: {
          headers: ["المشاعر", "مقالات", "يذكر", "المنظمات"],
          body: [
            [`${SmallSentiment}`, 62, 48, "المنظمات 1"],
            [`${SmallSentiment}`, 62, 48, "المنظمات 2"],
            [`${SmallSentiment}`, 62, 48, "المنظمات 3"],
            [`${SmallSentiment}`, 62, 48, "المنظمات 4"],
          ],
        },
      },
      {
        title: "المواقع",
        table: {
          headers: ["المشاعر", "مقالات", "يذكر", "موقع"],
          body: [
            [`${SmallSentiment}`, 62, 48, "موقع 1"],
            [`${SmallSentiment}`, 62, 48, "موقع 2"],
            [`${SmallSentiment}`, 62, 48, "موقع 3"],
            [`${SmallSentiment}`, 62, 48, "موقع 4"],
          ],
        },
      },
      {
        title: "الأحداث",
        table: {
          headers: ["المشاعر", "مقالات", "يذكر", "الأحداث"],
          body: [
            [`${SmallSentiment}`, 62, 48, "الأحداث 1"],
            [`${SmallSentiment}`, 62, 48, "الأحداث 1"],
            [`${SmallSentiment}`, 62, 48, "الأحداث 1"],
            [`${SmallSentiment}`, 62, 48, "الأحداث 1"],
          ],
        },
      },
    ],
    sentimentAnalysis: {
      title: "تحليل المشاعر عبر الزمن",
      image: `${LineGraph}`,
    },
  },
  {
    title: "Global Social Media",
    overview: `From 2020 to Q1 2022, 5 data sources were analyzed (2,000,000 documents) with 3,000 documents related to Air Quality.
          
          The overall sentiment of Air Quality from Arabic news is %60 positive, %10 negative and %30 neutral.`,
    sentimentWithLabels: `${BigSentiment}`,
    perception: [
      {
        title: "Positive Perception",
        text: `1,800 documents had positive public perception on Air Quality, these articles had mentioned following persons: Khaled Ahmed, Saad Misfer and Tariq Mohammad and mentioned the following organizations: Ministry of Water, Ministry of Rural Affairs and Center of Environmental Control. \n\nMoreover, positive public perception on Air Quality, mentioned the following events Green initiative, Global Environment forum and G20 Summit. Furthermore, Air Quality had been associated with the following positive mentions: Carbon Neutrality, +20 million trees and +200 air quality centers.`,
        table: {
          title: "Positive Summary (1,800 documents)",
          headers: ["People", "Organization", "Events", "Aspects"],
          body: [
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
          ],
        },
      },
      {
        title: "Negative Perception",
        text: `970 documents had negative public perception on Air Quality, these articles had mentioned following persons: Khaled Ahmed, Saad Misfer and Tariq Mohammad and mentioned the following organizations: Ministry of Water, Ministry of Rural Affairs and Center of Environmental Control.
                
                Moreover, positive public perception on Air Quality, mentioned the following events Green initiative, Global Environment forum and G20 Summit. Furthermore, Air Quality had been associated with the following positive mentions: Carbon Neutrality, +20 million trees and +200 air quality centers.`,
        table: {
          title: "Negative Summary (970 documents)",
          headers: ["People", "Organization", "Events", "Aspects"],
          body: [
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
          ],
        },
      },
      {
        title: "Neutral Perception",
        text: `584 documents had neutral public perception on Air Quality, these articles had mentioned following persons: Khaled Ahmed, Saad Misfer and Tariq Mohammad and mentioned the following organizations: Ministry of Water, Ministry of Rural Affairs and Center of Environmental Control.
                
                Moreover, positive public perception on Air Quality, mentioned the following events Green initiative, Global Environment forum and G20 Summit. Furthermore, Air Quality had been associated with the following positive mentions: Carbon Neutrality, +20 million trees and +200 air quality centers.`,
        table: {
          title: "Neutral Summary (584 documents)",
          headers: ["People", "Organization", "Events", "Aspects"],
          body: [
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
            [
              "Khalid Ahmed",
              "Ministry of Water",
              "Green Initiative",
              "Carbon Neutrality",
            ],
          ],
        },
      },
    ],
    summery: [
      {
        title: "Aspects",
        table: {
          headers: ["Aspects", "Mentions", "Articles", "Sentiment"],
          body: [
            ["Aspect 1", 84, 26, `${SmallSentiment}`],
            ["Aspect 2", 84, 26, `${SmallSentiment}`],
            ["Aspect 3", 84, 26, `${SmallSentiment}`],
            ["Aspect 4", 84, 26, `${SmallSentiment}`],
          ],
        },
      },
      {
        title: "People",
        table: {
          headers: ["People", "Mentions", "Articles", "Sentiment"],
          body: [
            ["People 1", 84, 26, `${SmallSentiment}`],
            ["People 2", 84, 26, `${SmallSentiment}`],
            ["People 3", 84, 26, `${SmallSentiment}`],
            ["People 4", 84, 26, `${SmallSentiment}`],
          ],
        },
      },
      {
        title: "Organizations",
        table: {
          headers: ["Organizations", "Mentions", "Articles", "Sentiment"],
          body: [
            ["Organizations 1", 84, 26, `${SmallSentiment}`],
            ["Organizations 2", 84, 26, `${SmallSentiment}`],
            ["Organizations 3", 84, 26, `${SmallSentiment}`],
            ["Organizations 4", 84, 26, `${SmallSentiment}`],
          ],
        },
      },
      {
        title: "Locations",
        table: {
          headers: ["Location", "Mentions", "Articles", "Sentiment"],
          body: [
            ["Location 1", 84, 26, `${SmallSentiment}`],
            ["Location 2", 84, 26, `${SmallSentiment}`],
            ["Location 3", 84, 26, `${SmallSentiment}`],
            ["Location 4", 84, 26, `${SmallSentiment}`],
          ],
        },
      },
      {
        title: "Events",
        table: {
          headers: ["Events", "Mentions", "Articles", "Sentiment"],
          body: [
            ["Events 1", 84, 26, `${SmallSentiment}`],
            ["Events 2", 84, 26, `${SmallSentiment}`],
            ["Events 3", 84, 26, `${SmallSentiment}`],
            ["Events 4", 84, 26, `${SmallSentiment}`],
          ],
        },
      },
    ],
    sentimentAnalysis: {
      title: "Sentiment analysis across time",
      image: `${LineGraph}`,
    },
  },
];

export const positiveData = {
  heading: "Positive Perception",
  text: `1,800 documents had positive public perception on Air Quality, these articles had mentioned following persons: Khaled Ahmed, Saad Misfer and Tariq Mohammad and mentioned the following organizations: Ministry of Water, Ministry of Rural Affairs and Center of Environmental Control. \n\nMoreover, positive public perception on Air Quality, mentioned the following events Green initiative, Global Environment forum and G20 Summit. Furthermore, Air Quality had been associated with the following positive mentions: Carbon Neutrality, +20 million trees and +200 air quality centers.`,
  table: {
    heading: "Positive Summary (1,800 documents)",
    headers: ["People", "Organization", "Events", "Aspects"],
    body: [
      [
        "Khalid Ahmed",
        "Ministry of Water",
        "Green Initiative",
        "Carbon Neutrality",
      ],
      [
        "Khalid Ahmed",
        "Ministry of Water",
        "Green Initiative",
        "Carbon Neutrality",
      ],
      [
        "Khalid Ahmed",
        "Ministry of Water",
        "Green Initiative",
        "Carbon Neutrality",
      ],
      [
        "Khalid Ahmed",
        "Ministry of Water",
        "Green Initiative",
        "Carbon Neutrality",
      ],
    ],
  },
};

export const negativeData = {
  heading: "Negative Perception",
  text: `970 documents had negative public perception on Air Quality, these articles had mentioned following persons: Khaled Ahmed, Saad Misfer and Tariq Mohammad and mentioned the following organizations: Ministry of Water, Ministry of Rural Affairs and Center of Environmental Control.
    
    Moreover, positive public perception on Air Quality, mentioned the following events Green initiative, Global Environment forum and G20 Summit. Furthermore, Air Quality had been associated with the following positive mentions: Carbon Neutrality, +20 million trees and +200 air quality centers.`,
  table: {
    heading: "Negative Summary (970 documents)",
    headers: ["People", "Organization", "Events", "Aspects"],
    body: [
      [
        "Khalid Ahmed",
        "Ministry of Water",
        "Green Initiative",
        "Carbon Neutrality",
      ],
      [
        "Khalid Ahmed",
        "Ministry of Water",
        "Green Initiative",
        "Carbon Neutrality",
      ],
      [
        "Khalid Ahmed",
        "Ministry of Water",
        "Green Initiative",
        "Carbon Neutrality",
      ],
    ],
  },
};

export const neutralData = {
  heading: "Neutral Perception",
  text: `584 documents had neutral public perception on Air Quality, these articles had mentioned following persons: Khaled Ahmed, Saad Misfer and Tariq Mohammad and mentioned the following organizations: Ministry of Water, Ministry of Rural Affairs and Center of Environmental Control.
    
    Moreover, positive public perception on Air Quality, mentioned the following events Green initiative, Global Environment forum and G20 Summit. Furthermore, Air Quality had been associated with the following positive mentions: Carbon Neutrality, +20 million trees and +200 air quality centers.`,
  table: {
    heading: "Neutral Summary (584 documents)",
    headers: ["People", "Organization", "Events", "Aspects"],
    body: [
      [
        "Khalid Ahmed",
        "Ministry of Water",
        "Green Initiative",
        "Carbon Neutrality",
      ],
      [
        "Khalid Ahmed",
        "Ministry of Water",
        "Green Initiative",
        "Carbon Neutrality",
      ],
      [
        "Khalid Ahmed",
        "Ministry of Water",
        "Green Initiative",
        "Carbon Neutrality",
      ],
    ],
  },
};

export const aspectData = {
  heading: "Aspects",
  table: {
    headers: ["Aspects", "Mentions", "Articles", "Sentiment"],
    body: [
      ["Aspect 1", 84, 26, `${SmallSentiment}`],
      ["Aspect 2", 84, 26, `${SmallSentiment}`],
      ["Aspect 3", 84, 26, `${SmallSentiment}`],
      ["Aspect 4", 84, 26, `${SmallSentiment}`],
    ],
  },
};

export const peopleData = {
  heading: "People",
  table: {
    headers: ["People", "Mentions", "Articles", "Sentiment"],
    body: [
      ["People 1", 84, 26, `${SmallSentiment}`],
      ["People 2", 84, 26, `${SmallSentiment}`],
      ["People 3", 84, 26, `${SmallSentiment}`],
      ["People 4", 84, 26, `${SmallSentiment}`],
    ],
  },
};

export const organizationData = {
  heading: "Organizations",
  table: {
    headers: ["Organizations", "Mentions", "Articles", "Sentiment"],
    body: [
      ["Organizations 1", 84, 26, `${SmallSentiment}`],
      ["Organizations 2", 84, 26, `${SmallSentiment}`],
      ["Organizations 3", 84, 26, `${SmallSentiment}`],
      ["Organizations 4", 84, 26, `${SmallSentiment}`],
    ],
  },
};

export const locationData = {
  heading: "Location",
  table: {
    headers: ["Location", "Mentions", "Articles", "Sentiment"],
    body: [
      ["Location 1", 84, 26, `${SmallSentiment}`],
      ["Location 2", 84, 26, `${SmallSentiment}`],
      ["Location 3", 84, 26, `${SmallSentiment}`],
      ["Location 4", 84, 26, `${SmallSentiment}`],
    ],
  },
};

export const eventsData = {
  heading: "Events",
  table: {
    headers: ["Events", "Mentions", "Articles", "Sentiment"],
    body: [
      ["Events 1", 84, 26, `${SmallSentiment}`],
      ["Events 2", 84, 26, `${SmallSentiment}`],
      ["Events 3", 84, 26, `${SmallSentiment}`],
      ["Events 4", 84, 26, `${SmallSentiment}`],
    ],
  },
};
