export default function BrokenHeartIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        file-rule="evenodd"
        clipRule="evenodd"
        d="M10.6541 5.82005L8.50002 9.32869L12.5 12.9565L11.5 15.5478L15 12.4383L12 8.81043L14 6.21913L13.5567 5.64469C15.3021 4.27852 17.8783 4.29654 19.6078 5.91347L19.7079 6.00709C22.013 8.16211 21.9707 11.831 19.6165 13.9323L12.52 20.2663C12.3437 20.4237 12.2555 20.5024 12.1569 20.535C12.055 20.5687 11.945 20.5687 11.8431 20.535C11.7445 20.5024 11.6563 20.4237 11.48 20.2663L4.38352 13.9323C2.02933 11.831 1.987 8.16211 4.29207 6.00709L4.39221 5.91347C6.19277 4.23012 8.91097 4.27973 10.6541 5.82005Z"
        fill="#C7993B"
      />
      <path
        file-rule="evenodd"
        clipRule="evenodd"
        d="M9.74914 6.20491C8.17327 5.10728 5.90905 5.33215 4.61184 7.06517L4.30249 7.47846C2.80647 9.47709 3.02484 12.3297 4.7995 14.0577L11.7521 20.8275C11.8773 20.9494 11.9466 21.0161 12 21.0591C12.0535 21.0161 12.1228 20.9494 12.2479 20.8275L19.2005 14.0577C20.9752 12.3298 21.1936 9.47708 19.6975 7.47846L19.3882 7.06517C18.0267 5.24622 15.6008 5.08888 14.0226 6.37727L13.3902 5.60262C15.3874 3.97215 18.4699 4.16962 20.1888 6.46593L20.4981 6.87921C22.2926 9.27654 22.0358 12.6927 19.8982 14.7742L12.9455 21.544L12.9317 21.5575C12.824 21.6624 12.7187 21.7649 12.6216 21.8424C12.5141 21.9282 12.3762 22.0176 12.196 22.0542C12.0666 22.0805 11.9334 22.0805 11.8041 22.0542C11.6238 22.0176 11.4859 21.9282 11.3784 21.8424C11.2813 21.7649 11.176 21.6624 11.0683 21.5574L11.0545 21.544L4.10188 14.7742C1.96419 12.6927 1.70746 9.27654 3.50192 6.87922L3.81128 6.46593C5.57914 4.10413 8.78861 3.9628 10.7773 5.7459L11.1208 6.05389L10.8414 6.421L9.72909 7.88234C9.409 8.30287 9.20152 8.57735 9.07698 8.79871C8.95949 9.00754 8.95951 9.09516 8.96961 9.15009C8.97971 9.20502 9.01088 9.28691 9.19499 9.44026C9.39015 9.60282 9.68173 9.78549 10.1305 10.0646L11.5398 10.941L11.568 10.9586C11.9497 11.196 12.2784 11.4003 12.519 11.5993C12.7768 11.8123 12.9931 12.066 13.0731 12.4215C13.1532 12.7771 13.0664 13.099 12.9249 13.4019C12.7927 13.6848 12.5833 14.0103 12.3401 14.3884L12.3221 14.4163L11.4206 15.818L10.5795 15.2771L11.4811 13.8754C11.7473 13.4614 11.9187 13.1929 12.0189 12.9786C12.1129 12.7774 12.1095 12.694 12.0976 12.6412C12.0857 12.5883 12.053 12.5115 11.8819 12.37C11.6996 12.2193 11.4296 12.0502 11.0117 11.7902L9.60239 10.9138L9.57284 10.8954C9.16189 10.6398 8.80994 10.421 8.55499 10.2086C8.28291 9.98201 8.05599 9.71087 7.98611 9.331C7.91624 8.95114 8.03182 8.61699 8.20545 8.30838C8.36814 8.0192 8.61918 7.68942 8.91229 7.30437L8.93336 7.27668L9.74914 6.20491Z"
        fill="white"
      />
    </svg>
  );
}
