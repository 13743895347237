export default function HeartIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.3212 14.2901L11.3617 21.1455L11.3618 21.1455C11.6339 21.4105 11.77 21.543 11.9345 21.5648C11.978 21.5705 12.022 21.5705 12.0655 21.5648C12.23 21.543 12.3661 21.4105 12.6383 21.1455L19.6788 14.2901C21.5786 12.4402 21.8112 9.46802 20.2222 7.34519L19.8169 6.80369C17.8688 4.20119 13.8467 4.6272 12.487 7.58002C12.2955 7.9959 11.7045 7.9959 11.513 7.58002C10.1533 4.6272 6.13117 4.20119 4.18313 6.80369L3.77781 7.34519C2.18882 9.46802 2.42137 12.4402 4.3212 14.2901Z"
        fill="#C7993B"
        stroke="white"
        strokeWidth="1.4"
      />
    </svg>
  );
}
