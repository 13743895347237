import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    SET_LOADER
} from "@constants/Types";

const initialState = {
    isLoggedIn: false,
    loading: false,
    user: {}
};
const accountReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOGIN_REQUEST: {
            return { ...state, loading: true };
        }

        case LOGIN_SUCCESS: {
            return {
                ...state,
                user: { ...state.user, ...action.payload },
                loading: false,
                isLoggedIn: true,
            };
        }

        case LOGIN_ERROR: {
            return { ...state, loading: false, isLoggedIn: false };
        }

        case SET_LOADER: {
            return { ...state, loading: false };
        }

        default:
            return state;
    }
};
export default accountReducer;