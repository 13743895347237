import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { topLinks } from "@utils/mock";
import CardModal from "@components/Modal/CardModal";

import "./index.scss";
import { useState } from "react";

interface Ilink {
  title: string;
  desc: string;
  url: string;
}

export const CardLinks = ({ data }: any) => {
  const { t } = useTranslation(["results", "common"]);
  const [modal, openModal] = useState<boolean>(false);
  const toggleModal = (state: boolean) => openModal(!state);
  const {
    rtl: lang,
    searching: { top_links },
  } = useSelector((state: any) => state.utils);
  const rtl = lang === "ar" && "change-align";
  let topLinks = [];
  if (data?.hits?.length) {
    topLinks = data?.hits?.length > 3 ? data.hits.slice(0, 3) : data.hits;
  }
  return (
    <>
      <div className="card-links w-100 h-100">
        <div className={`card-links-header`}>
          <h5 className="english">Top Links {data?.total ? <span className="count">({data?.total})</span> : null}</h5>
          <button
            className="dashboard-view-btn"
            onClick={() => toggleModal(modal)}
          >
            View More
          </button>
        </div>
        <div className={`card-links-body ${rtl}`}>
          {topLinks.map((item: any, index: number) => (
            <div className="subheading" key={`cardL-links-w${index}`}>
              <div className="subheading-header">
                <h6
                  dangerouslySetInnerHTML={{
                    __html: item?.highlight?.title?.text || item?.source?.title,
                  }}
                ></h6>
              </div>
              <div className="subheading-text">
                <p
                  className="m-0"
                  dangerouslySetInnerHTML={{
                    __html: item?.highlight?.body?.text || item?.source?.body,
                  }}
                ></p>
                <a href={item.source.url} target="_blank" rel="nofollow">
                  {item.source.url}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      {topLinks && top_links && (
        <CardModal
          title={"Links"}
          show={modal}
          sources={top_links}
          toggleModal={toggleModal}
          type={"links"}
        />
      )}
    </>
  );
};
