import { useSelector } from 'react-redux'
import SearchInput from '@components/Search';
import Sidebar from '@components/Sidebar';
import Filter from '@components/Filter'


export default function Dashboard({ children, onSearchChange, setFilter, filter }: any) {

    const { rtl: lang, searching } = useSelector((state: any) => state.utils)


    const rtl = lang === "ar" ? "arabic" : 'english'
    const onChangeHandler = (text: string) => {
        if (onSearchChange) {
            onSearchChange(text)
        }
    }

    return (
        <div className={`${rtl} dashboard`}>
            <div className='d-flex w-100'>
                <SearchInput onChange={onChangeHandler} />
            </div>
            <div className='dashboard-container d-flex'>
                <div className='filter-container'>
                    <Filter data={searching?.aggregations} setFilter={setFilter} filter={filter} />
                </div>
                <div className='main-container'>
                    {children}
                </div>
            </div>
            <Sidebar />
        </div>
    );
}
