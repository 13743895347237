import { Navigate } from 'react-router-dom';

const useAuth = () => {
    const token = localStorage.getItem("mozn")
    return token
}

export function PrivateOutlet({ children }: any) {
    const isAuthenticated = useAuth();
    return isAuthenticated ? children : <Navigate to="/" />;
}

