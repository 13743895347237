import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPDFReport, getReport } from "@redux/Actions/report";
import { Modal, Button, Tabs, Tab as TabView } from "react-bootstrap";
import { Link } from "react-router-dom";

import AnalysisChart from "@components/Chart/Analysis";
import PieChart from "@components/Chart";
import Tab from "@components/Tab";

import footerIco from "@assets/img/Icon-Footer.png";
import FileIcon from "@assets/Icon/FileIcon";
import ExportIcon from "@assets/Icon/Export";
import mozn_logo from "@assets/img/logo-light.png";
import ViewIcon from "@assets/Icon/View";

// @ts-ignore
import { reportTabs, basicInfo } from "@utils/reportMock";

import "./index.scss";

export default function ModalUI() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { reportData } = useSelector((state: any) => state.report);

  useEffect(() => {
    // add parameters based on social type
    dispatch(getReport());
  }, []);

  const setData = (items: any, row?: string) => {
    const sendData: any = [];
    for (let key in items) {
      const headers: any = [];
      let documents: any = [];
      for (let headerKey in items[key]) {
        if (headerKey === "documents") {
          documents = items[key][headerKey];
        } else if (headerKey !== "desc" && headerKey !== "top_articles") {
          headers.push(headerKey);
        }
      }
      const body: any = [];
      if (headers?.length && headers[0]?.length) {
        items[key][headers[0]]?.forEach((r: any, i: number) => {
          const row: any = [];
          headers.forEach((h: any) => {
            row.push(items[key][h][i]);
          });
          body.push(row);
        });
      }
      sendData.push({
        key: key,
        title: key + (row ? "" : " Perception"),
        desc: row ? "" : items[key].desc || "",
        table: {
          title: row ? "" : key + " Summary",
          headers,
          body,
          documents,
        },
      });
    }
    return sendData;
  };

  const perceptionData: any = setData(reportData?.perception);
  const interactiveData: any = setData(
    reportData?.interavtive_table,
    "interavtive"
  );

  return (
    <div className="report english">
      <div className="report-analysis-btn">
        <div className="btn btn-report-btn">
          <ViewIcon color="#C7993B" />
        </div>
        <Button variant="report-btn" onClick={handleShow}>
          <FileIcon />
        </Button>
        <Button
          className="d-flex align-items-center btn btn-report-btn download-link "
          style={{ padding: "5px 10px" }}
          onClick={() => dispatch(getPDFReport())}
        >
          <ExportIcon height={30} />
        </Button>
      </div>

      <Modal show={show} onHide={handleClose} className="report-modal english">
        <Modal.Body>
          <div className="report-modal-header">
            <h1 className="report-modal-heading">Air Quality in Saudi</h1>
            <div className="report-modal-time d-flex flex-column justify-content-center">
              <span>Today {new Date().toLocaleDateString()}</span>
              <span>
                {new Date().toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </div>
          </div>
          <Tabs
            className="report-modal-news"
            defaultActiveKey={"local_news"}
            onSelect={(eventKey) => dispatch(getReport(eventKey))}
          >
            {reportTabs.map((item: any, index: number) => {
              return (
                <TabView eventKey={item.key} title={item.title} key={index}>
                  {/* <h2 className="report-modal-title">{item.overview}</h2> */}
                  {/* <p
                    dangerouslySetInnerHTML={{
                      __html: item.basicInfo.overview,
                    }}
                  /> */}
                  <h2 className="report-modal-title">Overview</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: reportData?.Overview,
                    }}
                  />
                  <div style={{ height: 280 }}>
                    <PieChart
                      chart={reportData?.overview_graph}
                      isTitle={false}
                    />
                  </div>

                  <Tab
                    default={perceptionData[0]?.key}
                    data={perceptionData}
                    type="summary"
                  />

                  <h2 className="report-modal-title">
                    {"Summary Interactive Table"}
                  </h2>
                  <Tab
                    default={interactiveData[0]?.key}
                    data={interactiveData}
                  ></Tab>

                  {reportData?.sentiment_timeline && (
                    <AnalysisChart
                      data={reportData?.sentiment_timeline}
                      labels={Object?.keys(reportData?.sentiment_timeline)}
                      report={true}
                      reportTitle={"Sentiment analysis across time"}
                    />
                  )}

                  <div className="report-footer">
                    <div className="center-col"></div>
                    <div className="center-col report-footer_desc">
                      <div>
                        <img src={footerIco} height={75} />
                      </div>
                      <p>
                        {"Decision Support Center, Kingdom of Saudi Arabia"}
                      </p>
                    </div>
                    <div className="center-col"></div>
                  </div>
                </TabView>
              );
            })}
          </Tabs>
        </Modal.Body>
      </Modal>
    </div>
  );
}
