import entertainment from "@assets/demo-img/entertainment.jpg";
import air_quality from "@assets/demo-img/Airquality.jpg";
import yemen_war from "@assets/demo-img/yemen-war.png";
import traffic from "@assets/demo-img/traffic.jpg";
import sports from "@assets/demo-img/sports.png";
import Hajj from "@assets/demo-img/Hajj.jpg";

export const cards = [
  {
    title: "Air Quality",
    img_url: air_quality,

    desc: "Know more about what is the perception on Air Quality for Saudi Arabia.",
    results: 782,
  },
  {
    title: "Sports",
    img_url: sports,

    desc: "Know more about what is the perception on Sports for Saudi Arabia.",
    results: "TBD",
  },
  {
    title: "Yemen War",
    img_url: yemen_war,

    desc: "Know more about what is the perception on Yemen War for Saudi Arabia.",
    results: "TBD",
  },
  {
    title: "Entertainment",
    img_url: entertainment,

    desc: "Know more about what is the perception on Entertainment for Saudi Arabia.",
    results: "TBD",
  },
  {
    title: "Hajj and Umrah",
    img_url: Hajj,

    desc: "Know more about what is the perception on Hajj and Umrah for Saudi Arabia.",
    results: "TBD",
  },
  {
    title: "Traffic",
    img_url: traffic,
    desc: "Know more about what is the perception on Traffic for Saudi Arabia.",
    results: "TBD",
  },
];

export const socialSources: any = {
  en: [
    {
      title: "Henrik Jansson",
      desc: "Antiitet. Gamification Persiv. Primafili. Anavi.Tempometer.",
      img: "qoura",
    },
    {
      title: "Alexander Johnsson",
      desc: "Antiitet. Gamification Persiv. Primafili. Anavi.Tempometer.",
      img: "reddit",
    },
    {
      title: "Yvonne Sjögren",
      desc: "Antiitet. Gamification Persiv. Primafili. Anavi.Tempometer.",
      img: "linkedin",
    },
    {
      title: "Hans Lindholm",
      desc: "Antiitet. Gamification Persiv. Primafili. Anavi.Tempometer.",
      img: "twitter",
    },
  ],
  ar: [
    {
      title: "جريدة الجزيرة",
      desc: "لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج ",
      img: "qoura",
    },
    {
      title: "خالد عبدالرحمن",
      desc: "لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج ",
      img: "reddit",
    },
    {
      title: "جريدة الرياض",
      desc: " أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار ",
      img: "linkedin",
    },
    {
      title: "جريدة الرياض",
      desc: "كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور",
      img: "linkedin",
    },
  ],
};

export const topLinks: any = {
  en: [
    {
      title: "Töde kvasipatologi. ",
      desc: "Personalisering. Pinterest. Delig. Investering. Malaligt. USP. Konvertering. Soft land Bergman. LinkedIn. BOPIS. Megal. Content provider. Orände. Syrade. Robot retail. Ding. Bootstrapping. ",
      url: "www.Facilitera-Metataggar-Innovationshöjd.com",
    },
    {
      title: "Töde kvasipatologi. ",
      desc: "Personalisering. Pinterest. Delig. Investering. Malaligt. USP. Konvertering. Soft land Bergman. LinkedIn. BOPIS. Megal. Content provider. Orände. Syrade. Robot retail. Ding. Bootstrapping. ",
      url: "www.Degraf. Pneumaism. Min. Innovationssystem. Ingemar Åström. Plak. Terrafili. com",
    },
    {
      title: "BBC News",
      desc: "Personalisering. Pinterest. Delig. Investering. Malaligt. USP. Konvertering. Soft land Bergman. LinkedIn. BOPIS. Megal. Content provider. Orände. Syrade. Robot retail. Ding. Bootstrapping. ",
      url: "www.Degraf. Pneumaism. Min. Innovationssystem. Ingemar Åström. Plak. Terrafili. com",
    },
  ],
  ar: [
    {
      title: "جريدة الجزيرة",
      desc: "إضفاء الطابع الشخصي. بينتيريست. ديليج. الاستثمار. مالاليجت. USP. كونفيرتر. الأرض اللينة بيرجمان. ينكدين. بوبيس. ميغال. مزود المحتوى. أوراندي. سيريد. روبوت للبيع بالتجزئة. دينغ. التمهيد.",
      url: "www.Facilitera-Metataggar-Innovationshöjd.com ",
    },
    {
      title: "جريدة عكاظ",
      desc: "إضفاء الطابع الشخصي. بينتيريست. ديليج. الاستثمار. مالاليجت. USP. كونفيرتر. الأرض اللينة بيرجمان. ينكدين. بوبيس. ميغال. مزود المحتوى. أوراندي. سيريد. روبوت للبيع بالتجزئة. دينغ. التمهيد.",
      url: "www.Degraf. Pneumaism. Min. Innovationssystem. Ingemar Åström. Plak. Terrafili. com",
    },
    {
      title: "جريدة الرياض",
      desc: "إضفاء الطابع الشخصي. بينتيريست. ديليج. الاستثمار. مالاليجت. USP. كونفيرتر. الأرض اللينة بيرجمان. ينكدين. بوبيس. ميغال. مزود المحتوى. أوراندي. سيريد. روبوت للبيع بالتجزئة. دينغ. التمهيد.",
      url: "www.Jomån. Desbel. Pobebade. Snapchat. Eberir. Nisat. Blockchain.com",
    },
  ],
};

export const filterMock = [
  {
    label: "Government Performance",
    data: [
      "Government Performance",
      "Judicial System",
      "Fiscal Management",
      "Security & Safety",
    ],
  },
  {
    label: "Economy",
    data: [
      "Economic Performance",
      "Economic Resiliance",
      "Labor force & National Employment",
      "Business Environment & Innovation",
    ],
  },
  {
    label: "Sociaty",
    data: [
      "Education",
      "Healthcare",
      "Social Capital",
      "Social Welfare",
      "Culture & National Identity",
      "Individual Well-being",
      "Entertainment",
      "Housing",
    ],
  },
  {
    label: "Environment",
    data: [
      "Air",
      "Lands, Water & Biodiversity",
      "Waste Management",
      "Climate Change",
    ],
  },
  {
    label: "Infrastructure",
    data: [
      "Logistics & Transport",
      "ICT",
      "Electricity",
      "Water and Sanitation",
    ],
  },
];

const wordCloud: any = {
  en: [
    {
      text: "Såbel",
      value: 65,
    },

    {
      text: "Röfägen",
      value: 64,
    },
    {
      text: "Dihägt",
      value: 65,
    },
    {
      text: "Krossa",
      value: 66,
    },
    {
      text: "Sobinde",
      value: 65,
    },
    {
      text: "Nelans",
      value: 65,
    },
    {
      text: "Vant",
      value: 65,
    },
    {
      text: "Reneskapet",
      value: 65,
    },
    {
      text: "Rosäs",
      value: 65,
    },
    {
      text: "Unicorn",
      value: 65,
    },

    {
      text: "Vanera",
      value: 65,
    },

    {
      text: "Köd",
      value: 65,
    },

    {
      text: "Infralig",
      value: 65,
    },
  ],
  ar: [
    {
      text: "سابيل",
      value: 65,
    },

    {
      text: "روفجن",
      value: 64,
    },
    {
      text: "ديهاغت",
      value: 65,
    },
    {
      text: "كروسا",
      value: 66,
    },
    {
      text: "سوبيندي",
      value: 65,
    },
    {
      text: "نيلانز",
      value: 65,
    },
    {
      text: "فانت",
      value: 65,
    },
    {
      text: "رينسكابت",
      value: 65,
    },
    {
      text: "روزاس",
      value: 65,
    },
    {
      text: "وحيد القرن",
      value: 65,
    },

    {
      text: "فانيرا",
      value: 65,
    },

    {
      text: "كود",
      value: 65,
    },

    {
      text: "إنفرليج",
      value: 65,
    },
  ],
};

export default wordCloud;
