import { Modal } from "react-bootstrap";
import { getSocialIcon } from "@utils/mapping";
import "./index.scss";

export default function CardModal({ show, toggleModal, sources, type }: any) {
  return (
    <Modal
      show={show}
      onHide={() => toggleModal(show)}
      className="interceptive-modal cards-modal"
    >
      <div className="interceptive-modal-content card-modal">
        <div className="interceptive-modal-content_title card-modal-header">
          <h6>{type === "social" ? "Social Media Sources" : "Links"}</h6>
        </div>
        <div className="interceptive-modal-content_container card-modal-body">
          {sources &&
            sources.hits?.map((item: any, id: number) => (
              <div key={`card-modals-${id}`} className="card-modal-body-card">
                <div className="card-modal-body-card-title">
                  {type === "social" && <span>{getSocialIcon("twitter")}</span>}
                  <p>{item?.source?.title ? item.source.title : ""}</p>
                </div>
                {type === "social" && (
                  <div className="card-modal-body-card-text">
                    {item?.source?.body}
                  </div>
                )}
                <div
                  className="card-modal-body-card-link"
                  onClick={() => window.open(item.source?.url, "_blank")}
                >
                  <a>{item?.source?.url} </a>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
}
