import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import "./index.scss";

let data: any = [
  { name: "Negative", value: 600, color: "#C7993B" },
  { name: "Neutral", value: 200, color: "#C2C6CB" },
  { name: "Positive", value: 400, color: "#63BDBC" },
];

interface IPie {
  isTitle?: boolean;
  COLORS?: [string, string, string];
  chart?: [];
}

const renderCustomToolTip = ({ active, label, payload }: any) => {
  if (!payload || !payload?.length) return null;
  const count = payload[0].payload?.totalCount || 0;
  const percentage = (payload[0].value / count) * 100;
  return (
    <div
      className="tooltip-box bg-white"
      style={{ color: payload[0].payload?.fill }}
    >
      <p className="m-0"> Count: {payload[0].value}</p>
      <p className="m-0">
        {" "}
        {payload[0].name} {percentage ? `: ${percentage.toFixed(2)}%` : ""}{" "}
      </p>
    </div>
  );
};

export default function PieChartUI({
  isTitle = true,
  COLORS = ["#63BDBC", "#C2C6CB", "#C7993B"],
  chart,
}: IPie) {
  let totalCount = 0;
  if (chart?.length) {
    chart.sort((a: any, b: any) =>
      a.key > b.key ? -1 : b.key > a.key ? 1 : 0
    );
     totalCount = chart.reduce(
      (total: number, a: any) => total + a?.doc_count,
      0
    );
    data = chart.map((c: any) => {
      return {
        name: c.key,
        value: c.doc_count,
        totalCount,
        color:
          c.key === "Positive"
            ? "#63BDBC"
            : c.key === "Neutral"
            ? "#C2C6CB"
            : "#C7993B",
      };
    });
  }
  let renderLabel = function (entry: any) {
    return entry.name;
  };
  return (
    <div className="sentiment-chart english ">
      {isTitle && <h2>Sentiment Overview {totalCount ?  <span className="count">({totalCount})</span> : null}</h2>}
      <ResponsiveContainer width="100%" className="sentiment-chart-pie">
        <PieChart>
          <Pie
            dataKey="value"
            label={renderLabel}
            data={data}
            fill="#8884d8"
            cx="50%"
            cy="50%"
            outerRadius={80}
            innerRadius={60}
          >
            {data.map((entry: any, index: number) => (
              <Cell key={`cell-${index}`} fill={entry?.color} />
            ))}
          </Pie>
          <Tooltip content={renderCustomToolTip} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export function BasicPie({ chartData }: any) {
  let totalCount = 0;
  if (chartData) {
    chartData = Object.entries(chartData).map((item: any) => {
      totalCount += item[1];
      return {
        name: item[0],
        value: item[1],
      };
    });
  }
  return (
    <div className="sentiment-percent">
      {chartData.map((item: any) => {
        let cal: any = ((item.value / totalCount) * 100).toFixed();
        if(cal < 10){
          cal = `0${cal}`;
        }

       return (
            <div className={`${item.name} label`}>
              {`${cal}%`}
            </div>
        )
      })}
    </div>
  );
}
