import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const dummyData = [
  {
    name: "8. Mar",
    negative: 5,
    natural: 18,
    positive: 76,
  },
  {
    name: "8. Mar",
    negative: 8,
    natural: 27,
    positive: 87.5,
  },
  {
    name: "10. Mar",
    negative: 7,
    natural: 23,
    positive: 192,
  },
  {
    name: "10. Mar",
    negative: 7,
    natural: 15,
    positive: 76,
  },
  {
    name: "12. Mar",
    negative: 6,
    natural: 27,
    positive: 75,
  },
  {
    name: "12. Mar",
    negative: 2,
    natural: 10,
    positive: 52,
  },
  {
    name: "14. Mar",
    negative: 0,
    natural: 5,
    positive: 24,
  },
  {
    name: "14. Mar",
    negative: 5,
    natural: 24,
    positive: 83.5,
  },
  {
    name: "16. Mar",
    negative: 25,
    natural: 27,
    positive: 90,
  },
  {
    name: "16. Mar",
    negative: 18,
    natural: 25,
    positive: 100,
  },
  {
    name: "18. Mar",
    negative: 5,
    natural: 22,
    positive: 90,
  },
  {
    name: "18. Mar",
    negative: 5,
    natural: 22,
    positive: 80,
  },
  {
    name: "20. Mar",
    negative: 3,
    natural: 9.5,
    positive: 27,
  },
  {
    name: "20. Mar",
    negative: 1,
    natural: 12.5,
    positive: 26,
  },
  {
    name: "22. Mar",
    negative: 1,
    natural: 22,
    positive: 25,
  },
  {
    name: "22. Mar",
    negative: 7,
    natural: 20,
    positive: 76,
  },
  {
    name: "24. Mar",
    negative: 5,
    natural: 90,
    positive: 10,
  },
  {
    name: "24. Mar",
    negative: 12.5,
    natural: 30,
    positive: 87.5,
  },
  {
    name: "26. Mar",
    negative: 7,
    natural: 23,
    positive: 125,
  },
  {
    name: "26. Mar",
    negative: 3,
    natural: 22,
    positive: 85.5,
  },
  {
    name: "28. Mar",
    negative: 1,
    natural: 19,
    positive: 24,
  },
  {
    name: "28. Mar",
    negative: 12.5,
    natural: 25,
    positive: 73,
  },
  {
    name: "30. Mar",
    negative: 4,
    natural: 38,
    positive: 249,
  },
  {
    name: "30. Mar",
    negative: 3,
    natural: 19,
    positive: 168,
  },
  {
    name: "1. Apr",
    negative: 1,
    natural: 24.5,
    positive: 107,
  },
  {
    name: "1. Apr",
    negative: 3,
    natural: 25,
    positive: 102,
  },
];

export default function AnalysisChart({
  colors = ["#63BDBC", "#C2C6CB", "#C7993B"],
  data,
  labels
}: any) {
  let chartData = [];
  let i = 0;
  if (data) {
    for (let key in data) {
      chartData.push({
        name: labels[i],
        negative: data[key].Negative,
        neutral: data[key].Neutral,
        positive: data[key].Positive,
      });
      i++;
    }
  }
  else {
    chartData = dummyData;
  }

  return (
    <div className={`sentiment-chart english`}>
      <h2>Sentiment analysis across time</h2>
      <div className="chart-render-container">
        <ResponsiveContainer>
          <AreaChart
            className="chart-container"
            data={chartData}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >

            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" fontSize={12} />
            <YAxis fontSize={12} />
            <Tooltip />
            <Area
              dataKey="negative"
              stackId="1"
              stroke={colors[2]}
              fill={colors[2]}
              fillOpacity={1}
            />
            <Area
              dataKey="neutral"
              stackId="1"
              stroke={colors[1]}
              fill={colors[1]}
              fillOpacity={1}
            />
            <Area
              dataKey="positive"
              stackId="1"
              stroke={colors[0]}
              fill={colors[0]}
              fillOpacity={1}
            />
            <Legend
              align="left"
              payload={[
                {
                  id: "positive",
                  value: "Positive",
                  type: "square",
                  color: colors[0],
                },
                {
                  id: "negative",
                  value: "Negative",
                  type: "square",
                  color: colors[2],
                },
                {
                  id: "neutral",
                  value: "Neutral",
                  type: "square",
                  color: colors[1],
                },
              ]}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
