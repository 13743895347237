import "./index.scss"
import { useSelector } from 'react-redux'
import { useState } from "react";

export default function Search({ onChange }: any) {
    const [text, setText] = useState('');
    const { rtl } = useSelector((state: any) => state.utils)

    const placeholder = rtl === 'ar' ? 'ابحث هنا' : 'Search here';

    const changeHandler = (e: any) => {
        setText(e.target.value)
        if (onChange) {
            onChange(e.target.value);
        }
    }


    return <input value={text} className={`search-input ${rtl === 'ar' && 'change-align'}`} placeholder={placeholder} onChange={changeHandler} />
}
