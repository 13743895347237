export default function ExportIcon({ height }: any) {
    return (
        <svg width="25" height="29" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_160_504)">
                <path d="M12.1865 19.308L12.8811 20.0274L12.1617 20.722L11.4671 20.0026L12.1865 19.308ZM17.9679 15.1157L12.8811 20.0274L11.4919 18.5886L16.5786 13.6769L17.9679 15.1157ZM11.4671 20.0026L6.55541 14.9158L7.99417 13.5266L12.9058 18.6134L11.4671 20.0026ZM11.1866 19.2905L11.3004 12.7915L13.3001 12.8265L13.1863 19.3255L11.1866 19.2905Z" fill="#3D8685" />
                <path fillRule="evenodd" clipRule="evenodd" d="M13.3264 11.3267L13.3877 7.82727C13.3974 7.27507 12.9576 6.81958 12.4054 6.80991C11.8532 6.80024 11.3977 7.24005 11.388 7.79225L11.3267 11.2917L6.32748 11.2042C5.22308 11.1848 4.34347 10.2738 4.36281 9.16944L4.43285 5.17005C4.4522 4.06565 5.36317 3.18604 6.46757 3.20538L18.4657 3.41551C19.5701 3.43486 20.4497 4.34583 20.4304 5.45023L20.3604 9.44962C20.341 10.554 19.43 11.4336 18.3256 11.4143L13.3264 11.3267Z" fill="#3D8685" />
            </g>
            <defs>
                <filter id="filter0_d_160_504" x="-3.89925" y="0.100769" width="32.4166" height="32.4166" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_160_504" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_160_504" result="shape" />
                </filter>
            </defs>
        </svg>

    );
}
