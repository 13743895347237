import DashboardLayout from '@components/Layout'
import Card from '@components/Card'

import { cards } from '../utils/mock'

export default function Dashboard() {
  const col_resp = 'row row-cols-md-3 row-cols-sm-4  '

  return (
    <DashboardLayout>
            <div className={`${col_resp}  topics h-100 m-0`} >
                {
                    cards.map((item, i) => (
                        <div key={i} className='cards col'>
                            <Card data={item} i={i} />
                        </div>
                    ))
                }
            </div>
    </DashboardLayout>
  );
}
