import { Tabs, Tab } from "react-bootstrap";

import InteractiveTable from "@components/Table/Interactive";
import PerceptionTable from "@components/Table/Perception";

import "./index.scss";

export default function TabOptions(props: any) {
  const { data, type } = props;

  return (
    <Tabs
      className={`report-modal-tab ${type !== "summary" && "interactive-head"}`}
      defaultActiveKey={props.default}
    >
      {data.map((item: any, index: number) => (
        <Tab key={index} eventKey={item.key} title={item.title}>
          {item.desc}

          {type !== "summary" ? (
            <InteractiveTable
              type={type}
              headers={item.table.headers}
              title={item.table.title}
              body={item.table.body}
              documents={item.table.documents}
            />
          ) : (
            <PerceptionTable
              type={type}
              headers={item.table.headers}
              title={item.table.title}
              body={item.table.body}
            />
          )}
        </Tab>
      ))}
    </Tabs>
  );
}
