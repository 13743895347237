import { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";

import main_logo from '@assets/img/login-logo.png'
import mozn_logo from '@assets/img/mozn-logo.png'
import tree_logo from '@assets/img/tree.png'
import Loader from '@components/Loader';

import { login, setloader } from "@redux/Actions/auth";

export default function Login() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [msg, setMsg] = useState('')
  const { loading } = useSelector((state: any) => state.auth)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: "onSubmit" });

  const loginSubmit = (data: any) => {
    dispatch(login(data))
      .then((res: any) => {
        setMsg('')
        navigate('/dashboard')
      })
      .catch((e: any) => {
        dispatch(setloader(false))
        if (e?.message) {
          setMsg(e.message)
        }
      })
  }



  return (
    <form className="login" onSubmit={handleSubmit(loginSubmit)}>
      <div className="login-info center-col">
        <div className="login-logo">
          <img src={main_logo} />
        </div>
        <h2 className="login-info-title">Public Perception Platform</h2>


        <input
          placeholder="Username"
          className="login-info-input form-control"
          {...register("username", { required: true })}
        />
        <input
          placeholder="Password"
          className="login-info-input form-control"
          {...register("password", { required: true })}
          type="password"
        />
        <button className="btn d-flex justify-content-center" type='submit'> {loading ? <Loader /> : "Login"}</button>
        <div className="login-option">
          <span>Forgot Password?</span>
          <span>Don’t have an account?</span>
        </div>
        {msg && <p style={{ color: '#ff4f4f' }} className='text-sm'>{msg}</p>}
      </div>


      <div className="login-footer-logo-a">
        <img src={tree_logo} />
      </div>
      <div className="login-footer-logo-b">
        <img src={mozn_logo} />
      </div>
    </form>
  );
}
