import { useEffect, useState } from "react";
import moment from "moment";
import { Collapse } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { filterMock } from "@utils/mock";
import DateRange from "@components/DateRange";
import { FilterIcon } from "@assets/Icon/FilterIcon";
import collapseIcon from "@assets/img/collapse-icon.png";
import { languageTranslate, setFilterDropdownTags } from "@redux/Actions/utils";
import "./index.scss";

export default function Filter({ data }: any) {
  const [openLang, setOpenLang] = useState(false);
  const filterOptions: any = data && Object?.entries(data);
  const [filterLabels, setFilterLabels] = useState(filterOptions);

  const { filterDropdown } = useSelector((state: any) => state.utils);
  const { pathname } = useLocation();
  const isResult = pathname.split("/").pop() === "results";
  const filterLength = !isResult ? filterMock.length : filterLabels?.length;
  const toggle = new Array(filterLength).fill(false);
  const [filterToggle, setFilterToggle] = useState(toggle);

  const { i18n, t } = useTranslation(["common"]);
  const { rtl } = useSelector((state: any) => state.utils);

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.localStorage.getItem("i18nextLng")?.length > 2) {
      i18n.changeLanguage("en");
    }
  }, []);

  useEffect(() => {
    const options: any = data && Object?.entries(data);
   if(options?.length){
     options.forEach((o: any) => {
         if(o[0] === 'dates'){
             const isDate = filterDropdown.find((g: any) =>  g?.field === 'date' );
             o[1] ={
                greater_than_or_equal : isDate?.greater_than_or_equal,
                less_than_or_equal : isDate?.less_than_or_equal
            };

         }
      else if(o?.length){
         o[1].slice(0, 4).forEach((f: any) =>{
           const isHave = filterDropdown.find((g: any) => g.value === f.key && o[0] === g.field )
           f.value = !!isHave;
         })
       }
     });
   }
    setFilterLabels(options)
  }, [data]);

  const handleLanguageChange = (language: string) => {
    const isArabic = language;
    i18n.changeLanguage(isArabic);
    dispatch(languageTranslate(isArabic));
  };

  const handleChange = (
    field: string,
    value: any,
    parentIndex: number,
    index: number
  ) => {
    const filtersValues = [...filterLabels];
    filtersValues[parentIndex][1][index].value =
      !filtersValues[parentIndex][1][index].value;
    console.log("filtersValues..................", filtersValues);
    dispatch(setFilterDropdownTags(filtersValues));
  };

  const handleDateChange = (start: any, end: any, id: number) => {
    const filtersValues: any = [...filterLabels];
    const dates: any = {};
    if (start) {
      // dates.push({
      //     greater_than_or_equal: moment(start).set('month', 11).set('date', 31).format('YYYY-MM-DD')
      // })
        dates.greater_than_or_equal = moment(start).format('YYYY-MM-DD')
    }
    if (end) {
      // dates.push({
      //     less_than_or_equal : moment(end).format('YYYY-MM-DD')
      // })
        dates.less_than_or_equal = moment(end).set('month', 11).set('date', 31).format('YYYY-MM-DD')

    }
    filtersValues[id][1] = dates;
    dispatch(setFilterDropdownTags(filtersValues));
  };

  const filterToggleHandler = (index: number) => {
    const temp = [...filterToggle];
    temp[index] = !temp[index];
    setFilterToggle(temp);
  };

  return (
    <div className={`filters`}>
      <div className="heading reset-align">
        <h5 className="english">Filters</h5>
        <FilterIcon fill="#273B4A" />
      </div>
      {!isResult ? (
        <div key={`sub-filter-dashboard`} className="sub-filters">
          {filterMock.map((item: any, itemIdx: number) => (
            <div key={`sub-filters-${itemIdx}`}>
              {/*<h6 className="filter-title" key={`label-${itemIdx}`}>{item.label}</h6>*/}
              <button
                className={`filter-title english ${
                  filterToggle[itemIdx] ? "open" : "close"
                }`}
                type="button"
                onClick={() => filterToggleHandler(itemIdx)}
              >
                <img src={collapseIcon} alt="icon" />
                {item.label}
              </button>
              <Collapse in={filterToggle[itemIdx]}>
                <div>
                  {item.data.map((label: any, id: number) => (
                    <div
                      className="checkbox-filter"
                      key={`dashboard-checkbox-${id}`}
                    >
                      <input type={"checkbox"} />
                      <label>{label} </label>
                    </div>
                  ))}
                </div>
              </Collapse>
            </div>
          ))}
        </div>
      ) : (
        <div key={`sub-filter-1`} className="sub-filters  ">
          <button
            className={`filter-title english w-100  ${openLang ? "open" : "close"}`}
            type="button"
            onClick={() => setOpenLang(!openLang)}
          >
            <img src={collapseIcon} alt="icon" />
            {/*{t("common:newsAndSource")}*/}
            News & Sources
          </button>
          <Collapse in={openLang}>
            <div className="">
              <div className="radio-button ">
                <input
                  onChange={() => handleLanguageChange("ar")}
                  type="radio"
                  name="language"
                  id="ar-lang"
                  value="ar"
                  checked={i18n.language === "ar"}
                />
                <label className="english" htmlFor="ar-lang">
                  {/*{t("common:arabic")}*/}
                  Local (Arabic)
                </label>
              </div>
              <div className="radio-button">
                <input
                  onChange={() => handleLanguageChange("en")}
                  type="radio"
                  name="language"
                  id="en-lang"
                  value="en"
                  checked={i18n.language === "en"}
                />
                <label   className="english" htmlFor="en-lang">
                  {/*{t("common:global")}*/}
                  Global (English)
                </label>
              </div>
            </div>
          </Collapse>
          {filterLabels?.map((item: any, id: number) => (
            <div key={`filter-${id}`}>
              <button
                className={`filter-title english  w-100 ${
                  filterToggle[id] ? "open" : "close"
                }`}
                type="button"
                onClick={() => filterToggleHandler(id)}
              >
                <img src={collapseIcon} alt="icon" />
                {item[0]}
              </button>
              <Collapse in={filterToggle[id]}>
                <div>
                  {item[0] === "dates" ? (
                    <DateRange
                      start={item[1]?.greater_than_or_equal}
                      end={item[1]?.less_than_or_equal}
                      to={"to"}
                      onChange={(start: any, end: any) =>
                        handleDateChange(start, end, id)
                      }
                    />
                  ) : (
                    item[1]?.slice(0, 4).map((item2: any, key: number) => (
                      <div
                        className="checkbox-filter"
                        key={`dashboard-checkbox-${key}`}
                      >
                        <input
                          type={"checkbox"}
                          onChange={() =>
                            handleChange(item[0], item2.key, id, key)
                          }
                          checked={!!item2?.value}
                        />
                        <label>{`${item2.key} (${item2.doc_count})`}</label>
                      </div>
                    ))
                  )}
                </div>
              </Collapse>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
