import { useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  Font,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import { useSelector, useDispatch } from "react-redux";

import { pdfData } from "./mock";
import { getPDFReport } from "@redux/Actions/report";
import FontCairo from "@assets/fonts/Cairo/Cairo-VariableFont_wght.ttf";
import FontInterRegular from "@assets/fonts/Inter/Inter-Regular.ttf";
import FontInterBold from "@assets/fonts/Inter/Inter-Bold.ttf";

import { styles } from "./styles";

Font.register({
  family: "Cairo",
  format: "truetype",
  fonts: [
    {
      src: FontCairo,
    },
  ],
});
Font.register({
  family: "Inter",
  format: "truetype",
  fonts: [
    {
      src: FontInterRegular,
    },
    {
      src: FontInterBold,
    },
  ],
});

const PdfTable = ({ data, lang }) => {
  return (
    <View wrap={false} style={styles.subSection}>
      <Text
        style={lang == "ar" ? styles.arabicSubHead : styles.subSectionHeading}
      >
        {data.title}
      </Text>
      <View style={styles.tblHeader}>
        {data.table.headers.map((item) => (
          <Text style={styles.tableTextH}>{item}</Text>
        ))}
      </View>
      <View style={styles.tblBody}>
        {data.table.body.map((item, key) => {
          return (
            <View style={styles.tblRowSentiment}>
              {item.map((item2) => {
                return typeof item2 === "string" &&
                  item2.slice(0, 4) === "data" ? (
                  <View style={styles.sentimentImageContainer}>
                    <Image style={styles.smallSentiment} source={item2} />
                  </View>
                ) : (
                  <Text style={styles.sentimentCellContainer}>{item2}</Text>
                );
              })}
            </View>
          );
        })}
      </View>
    </View>
  );
};

const AnalysisChart = ({ title, image }) => {
  return (
    <View wrap={false} style={styles.lineGraphContainer}>
      <Text style={styles.lineGraphHead}>{title}</Text>
      <Image style={styles.lineGraph} source={image} />
    </View>
  );
};

const PerceptionTable = ({ data, lang }) => {
  return (
    <View
      wrap={false}
      style={lang == "ar" ? styles.section : styles.sectionEng}
    >
      <Text style={lang == "ar" ? styles.arabicSubHead : styles.subHeading}>
        {data.title}
      </Text>
      <Text style={lang == "ar" ? styles.arabicText : styles.text}>
        {data.text}
      </Text>
      <View wrap={false} style={styles.table}>
        <Text
          style={lang == "ar" ? styles.arabicTblHeading : styles.tblHeading}
        >
          {data.table.title}
        </Text>
        <View style={styles.tblHeader}>
          {data.table.headers.map((item3, key3) => (
            <Text key={`${data.title}-${key3}`} style={styles.tableTextH}>
              {item3}
            </Text>
          ))}
        </View>
        <View style={styles.tblBody}>
          {data.table.body.map((item3, key3) => {
            return (
              <View key={`${data.title}-tbl-${key3}`} style={styles.tblRow}>
                {item3.map((item4, key4) => (
                  <Text
                    key={`${data.title}-tblCell-${key4}`}
                    style={styles.tblCell}
                  >
                    {item4}
                  </Text>
                ))}
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
};

const reverseNumbers = (string, item) => {
  return string;
  if (item.lang !== "ar") {
    return string;
  }
  var regex = /\d+(?:\.\d+)?/g;
  var matches = string.match(regex);
  var res = string;

  for (var i = 0; i < matches.length; i++) {
    res = res.replace(matches[i], matches[i].split("").reverse().join(""));
  }
  return res;
};
// Create Document Component
const MyDocument = () => {
  return (
    <Document>
      {pdfData.map((item, key) => (
        <Page style={item.lang == "ar" ? styles.body : styles.bodyEng}>
          <View
            fixed
            render={({ pageNumber }) =>
              pageNumber === 1 ||
              pageNumber === 5 ||
              pageNumber === 9 ||
              pageNumber === 13 ? (
                <View>
                  <Text style={styles.firstHeader}>
                    Public Perception on Air Quality Report
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={styles.date}>
                      Today {new Date().toLocaleDateString()}
                    </Text>
                    <Text style={styles.date}>
                      {new Date().toLocaleTimeString()}
                    </Text>
                  </View>
                </View>
              ) : (
                <View>
                  <Text style={styles.otherPage}>
                    Public Perception on Air Quality Report
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 5,
                      marginBottom: 10,
                    }}
                  >
                    <Text style={styles.date}>
                      Today {new Date().toLocaleDateString()}
                    </Text>
                    <Text style={styles.date}>
                      {new Date().toLocaleTimeString()}
                    </Text>
                  </View>
                </View>
              )
            }
          />
          <Text style={styles.header}>{item.title}</Text>
          <Text style={styles.subHeading}>Overview</Text>
          <Text style={item.lang == "ar" ? styles.arabicText : styles.text}>
            {reverseNumbers(item.overview, item)}
          </Text>
          <View style={styles.imageContainer}>
            <Image style={styles.image} source={item.sentimentWithLabels} />
          </View>
          {item.perception.map((item2, key2) => (
            <PerceptionTable
              data={item2}
              lang={item.lang}
              key={`${item.title}-${key2}`}
            />
          ))}
          <View break style={styles.section}>
            {item.summery.map((item2, key2) => (
              <PdfTable
                lang={item.lang}
                key={`${item2.title}-tbl-${key2}`}
                data={item2}
              />
            ))}
          </View>
          <AnalysisChart
            title={item.sentimentAnalysis.title}
            image={item.sentimentAnalysis.image}
          />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} of ${totalPages}`
            }
            fixed
          />
        </Page>
      ))}
    </Document>
  );
};

export default function PdfUI() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPDFReport());
  }, []);

  const { reportPDFData } = useSelector((state) => state.report);

  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <MyDocument />
    </PDFViewer>
  );
}
